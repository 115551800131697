import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    IT_PENDLIST,
    IT_PENDCOUNT,
    IT_COMPLETECOUNT,
    IT_COMPLETELIST,
    MRD_API_RES,
    ADMIN_LIST,
    ADMIN_EXCEL_LIST,
    ADMIN_COUNT
  } from '../../shared/constants/ActionTypes';
  import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
  import {fetchError, onWriteCloudWatchLog} from './Common';
  //AWS cloud watch name
  var streamname = 'Redux_Actions';
  var filename = 'ITAdminActions.js';

  export const onGetPendingITList = (value) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      jwtAxios
        .post('v1/mrdUser/getITadminPendListJwt',value)
        .then((data) => {
          try {
            var body = {jwtToken: data.data.body['token']};
            jwtAxios
              .post('v1/mrdUser/getITadminPendList', body)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: IT_PENDLIST,
                  payload: data?.data?.body?.list,
                });
                dispatch({
                  type: IT_PENDCOUNT,
                  payload: data?.data?.body?.count,
                });
             
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetPendingITList',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetPendingITList',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPendingITList',
              '0',
              error,
            ),
          );
        });
    };
  };
  export const onGetCompleteITList = (value) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      jwtAxios
        .post('v1/mrdUser/getITadminCompletedListJwt',value)
        .then((data) => {
          try {
            var body = {jwtToken: data.data.body['token']};
            jwtAxios
              .post('v1/mrdUser/getITadminCompletedList', body)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: IT_COMPLETELIST,
                  payload: data?.data?.body?.list,
                });
                dispatch({
                  type: IT_COMPLETECOUNT,
                  payload: data?.data?.body?.count,
                });
             
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetCompleteITList',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetCompleteITList',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetCompleteITList',
              '0',
              error,
            ),
          );
        });
    };
  };
  export const onGetCompletedITList = (value) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      jwtAxios
        .post('v1/mrdUser/getITadminPendListJwt',value)
        .then((data) => {
          try {
            var body = {jwtToken: data.data.body['token']};
            jwtAxios
              .post('v1/mrdUser/getITadminPendList', body)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: IT_PENDLIST,
                  payload: data?.data?.body?.list,
                });
                dispatch({
                  type: IT_PENDCOUNT,
                  payload: data?.data?.body?.count,
                });
             
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetPendingITList',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetPendingITList',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPendingITList',
              '0',
              error,
            ),
          );
        });
    };
  };

  export const submitITResponse = (value) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      jwtAxios
        .post('v1/mrdUser/submitITAdminResJwt',value)
        .then((data) => {
          try {
            var body = {jwtToken: data.data.body['token']};
            jwtAxios
              .post('v1/mrdUser/submitITAdminRes', body)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                if(data?.data?.body =='Success'){
                if(value?.status == 1){
                  dispatch({
                    type: MRD_API_RES,
                    payload:'Accepted',
                  });
                }
                if(value?.status == 2){
                  dispatch({
                    type: MRD_API_RES,
                    payload: 'Rejected',
                  });
                }
                }
                else{
                  dispatch({
                    type: MRD_API_RES,
                    payload: data?.data?.body,
                  });
                }
                
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetPendingITList',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetPendingITList',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPendingITList',
              '0',
              error,
            ),
          );
        });
    };
  };

  export const getadminReport = (value) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      jwtAxios
        .post('v1/mrdUser/adminReportJwt',value)
        .then((data) => {
          try {
            var body = {jwtToken: data.data.body['token']};
            jwtAxios
              .post('v1/mrdUser/adminReport', body)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                if(value?.tag=='excel')
                {
                  dispatch({
                    type: ADMIN_EXCEL_LIST,
                    payload: data?.data?.body?.list,
                  });
                  if(data?.data?.body?.list && data?.data?.body?.list?.length == 0)
                  {
                    dispatch({
                      type: MRD_API_RES,
                      payload: 'No record found',
                    });
                  }
                }
                else{
                  dispatch({
                    type: ADMIN_LIST,
                    payload: data?.data?.body?.list,
                  });
                  dispatch({
                    type: ADMIN_COUNT,
                    payload: data?.data?.body?.count,
                  });
                  if(data?.data?.body?.list && data?.data?.body?.list?.length == 0)
                    {
                      dispatch({
                        type: MRD_API_RES,
                        payload: 'No record found',
                      });
                    }
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'getadminReport',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'getadminReport',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getadminReport',
              '0',
              error,
            ),
          );
        });
    };
  };