import React, {useContext, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {matchRoutes} from 'react-router-config';
import qs from 'qs';
import AppContext from './AppContext';
import {useAuthToken} from './AppHooks';
import {Loader} from '../index';
import PropTypes from 'prop-types';
import {checkPermission} from './Utils';
import {AuthType} from '../../shared/constants/AppEnums';
import {initialUrl, pharminitialUrl,labInitialurl, radiologyInitialurl,itadminInitialurl} from '../../shared/constants/AppConst';
import {setInitialPath,checkAuthentication,  onCognitoUserSignOut,
  onJWTAuthSignout,
  signOutReset,
  onSignOutAuth0User,
  onSignOutFirebaseUser,} from '../../redux/actions';
import {PATIENT_COMPLETED_DATA,PATIENT_COMPLETED_COUNT,CHECK_MODE} from  '../../shared/constants/ActionTypes';
const AuthRoutes = ({children}) => {
  const {pathname, search} = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    routes,
    changeNavStyle,
    updateThemeStyle,
    updateThemeMode,
    setRTL,
  } = useContext(AppContext);

  const [loading, user] = useAuthToken();
  const initialPath = useSelector(({settings}) => settings.initialPath);
  const modeCheck = useSelector(({auth}) => auth.checkMode);
  const currentRoute = matchRoutes(routes, pathname)[0].route;
  let isPermitted = checkPermission(currentRoute.auth, user ? user.role : null);
  useEffect(() => {
    function setInitPath() {
     if(initialPath && initialPath.includes('passwordRequest') && initialPath.includes('/')){
      if(user && user != null){
        dispatch({
          type: CHECK_MODE,
          payload: 'Reset-Password',
        });
       // logout functionality 
       window.location.reload();
       window.localStorage.clear();
       localStorage.clear();
       dispatch({
         type: PATIENT_COMPLETED_DATA,
         payload: [],
       });
       dispatch({
         type: PATIENT_COMPLETED_COUNT,
         payload: [],
       });
       if (user && user?.authType === AuthType.AWS_COGNITO) {
         dispatch(onCognitoUserSignOut());
       } else if (user && user?.authType === AuthType.FIREBASE) {
         dispatch(onSignOutFirebaseUser());
       } else if (user && user?.authType === AuthType.AUTH0) {
         dispatch(onSignOutAuth0User());
       } else if (user && user.authType === AuthType.JWT_AUTH) {
         let pushdata = {
           userId: ' ',
           userCode: user.credentialid,
           onlineStatus: 'Logout',
         };
         dispatch(signOutReset(pushdata,initialPath,history));
       }
      
      }
      else{
        let data=initialPath.split('/')[2];
        if(data){
          let passData={
            value:data
          }
          dispatch(checkAuthentication(passData,history));
        }
        else{
          history.push('/error-pages/error-404') 
        }
      }
     }
     else{
      if (
        initialPath === '/' &&
        [
          '/signin',
          '/reset-password',
          '/error-pages/error-404',
          '/forget-password',
        ].indexOf(pathname) === -1
      ) {
        if (isPermitted) {
          dispatch(setInitialPath(pathname));
        } else {
          dispatch(setInitialPath(undefined));
        }
      }
     }
     
    }

    setInitPath();
  }, [dispatch, isPermitted, initialPath, pathname, user]);

  const logoutUser = () => {
    window.location.reload();
    window.localStorage.clear();
    localStorage.clear();
    dispatch({
      type: PATIENT_COMPLETED_DATA,
      payload: [],
    });
    dispatch({
      type: PATIENT_COMPLETED_COUNT,
      payload: [],
    });
    if (user && user?.authType === AuthType.AWS_COGNITO) {
      dispatch(onCognitoUserSignOut());
    } else if (user && user?.authType === AuthType.FIREBASE) {
      dispatch(onSignOutFirebaseUser());
    } else if (user && user?.authType === AuthType.AUTH0) {
      dispatch(onSignOutAuth0User());
    } else if (user && user.authType === AuthType.JWT_AUTH) {
      let pushdata = {
        userId: ' ',
        userCode: user.credentialid,
        onlineStatus: 'Logout',
      };
      dispatch(onJWTAuthSignout(pushdata));
    }
  };

  useEffect(() => {
    function handleQueryParams() {
      const query = qs.parse(search.split('?')[1]);
      if (query.layout) {
        changeNavStyle(query.layout);
      }
      if (query.mode) {
        updateThemeMode(query.mode);
      }
      if (query.rtl) {
        setRTL(true);
      }
      if (query.style) {
        updateThemeStyle(query.style);
      }
    }

    if (search) {
      handleQueryParams();
    }
  }, [changeNavStyle, updateThemeStyle, updateThemeMode, setRTL, search]);

  useEffect(() => {
    if (!loading) {
      if(modeCheck != ''){
        history.push(initialPath)
      }
      else if (!user && !isPermitted) {
        history.push('/signin'); // allowed route
        window.location.reload();
      } else if (user && !isPermitted) {
        history.push('/error-pages/error-404'); // Not found
      } else if (user && isPermitted) {
        if (pathname === '/' || pathname === '/signin') {
          if (Number(user?.roleId) == 1 || Number(user?.roleId) == 2 || Number(user?.roleId) == 3) {
            history.push(initialUrl);
          }
          if (Number(user?.roleId) == 4) {
            history.push(pharminitialUrl);
          }
          if (Number(user?.roleId) == 5) {
            history.push(labInitialurl);
          }
          if (Number(user?.roleId) == 6) {
            history.push(radiologyInitialurl);
          }
          if (Number(user?.roleId) == 7) {
            history.push(itadminInitialurl);
          }
        } else if (
          initialPath &&
          initialUrl !== initialPath &&
          (initialPath !== '/' || initialPath !== '/signin')
        ) {
          // if(initialPath.includes('passwordRequest')){
          //   history.push(initialPath)
          // }
          // else
          {
            if (Number(user?.roleId) == 1 || Number(user?.roleId) == 2 || Number(user?.roleId) == 3) {
              history.push(initialUrl);
            }
            if (Number(user?.roleId) == 4) {
              history.push(pharminitialUrl);
            }
            if (Number(user?.roleId) == 5) {
              history.push(labInitialurl);
            }
            if (Number(user?.roleId) == 6) {
              history.push(radiologyInitialurl);
            }
            if (Number(user?.roleId) == 7) {
              history.push(itadminInitialurl);
            }
          }
         
        }
      }
    }
  }, [user, loading, initialPath, isPermitted, pathname, history, user]);

  return loading ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};
